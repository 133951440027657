import convertDate from "../utils-functions/convertDate";

export default async function getGroupSales(
    sales,
    user,
    startDate,
    endDate,
    type = ["project"],
    status = ["BOOKED", "BILLED", "TO-INVOICE"],
    users
) {
    let groupSales = {};
    let personalSales = {};
    if (!sales) return null;
    if (!user) return null;

    let filteredSales = sales.filter((sale) => sale.involveId.includes(user.id));

    filteredSales.forEach((sale) => {
        sale.involveId.every((agentId) => {
            if (
                convertDate(sale.bookingDate) >= startDate &&
                convertDate(sale.bookingDate) <= endDate &&
                checkType(sale.type, type) &&
                checkStatus(sale, status)
            ) {
                pushToObject(groupSales, agentId, sale);
                if (sale.agentEmail === agentId) pushToObject(personalSales, agentId, sale);

                //skip uplines
                if (agentId === user.id) return false;
            }
            return true;
        });
    });

    let groupSalesArray = [];
    Object.keys(groupSales).forEach((email) => {
        const fUser = users.find((user) => user.id === email);
        const userName = fUser ? fUser.agent : email;
        groupSalesArray.push({
            name: userName,
            email: email,
            groupValue: groupSales[email],
            personalValue: personalSales[email] ? personalSales[email] : 0
        });
    });
    groupSalesArray.sort((a, b) => {
        return b.groupValue - a.groupValue;
    });

    return groupSalesArray;
}

const checkStatus = (sale, status) => {
    if (status.includes(sale.status)) {
        return true;
    } else return false;
};

function pushToObject(groupSales, agentId, sale) {
    groupSales[agentId] = groupSales[agentId]
        ? groupSales[agentId] + parseFloat(sale.netPricePortion)
        : parseFloat(sale.netPricePortion);
}

function checkType(saleType, type) {
    if (type.includes(saleType)) {
        return true;
    } else return false;
}
