import { Container } from "@mui/system";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import UploadExcel from "../components/UploadExcel";
import { AuthContext } from "../context/AuthContext";
import { UsersContext } from "../context/UsersContext";
import loadUsers from "../functions/loadUsers";
import uploadAgentsData from "../seeding-functions/uploadAgentsData";
import { GridContainer, GridFlexBox, Loading, Title } from "../themes/themes";

export default function SeedAgents() {
    const { users, setUsers } = useContext(UsersContext);
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const users = await loadUsers(user);
            setUsers(users);
            setLoading(false);
        };
        if (!users) {
            getData();
        }
    }, []);

    const upload = (data) => {
        uploadAgentsData(data, users);
    };

    return (
        <Container>
            <GridContainer>
                <GridFlexBox>
                    <Title>Seed Agents</Title>
                </GridFlexBox>
                <Loading loading={loading} />
                <GridFlexBox>
                    <UploadExcel upload={upload} />
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}
