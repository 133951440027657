import React, { useEffect, useState } from "react";
import { createContext } from "react";
import convertDate from "../utils-functions/convertDate";
import getDayMonthYear from "../utils-functions/getDayMonthYear";
import { removeDuplicate } from "../utils-functions/removeDuplicate";

export const    SalesContext = createContext();

export const SalesProvider = ({ children }) => {
    const [sales, setSales] = useState();
    const [yearsLoaded, setYearsLoaded] = useState([]);

    useEffect(() => {
        let chosenSales = [];
        if (sales) {
            chosenSales = [
                ...sales.slice(0, 1),
                ...sales.slice(sales.length - 2, sales.length - 1)
            ];
            let chosenYears = chosenSales.map((sale) => {
                let year = parseInt(
                    getDayMonthYear(convertDate(sale.bookingDate)).year
                );
                return year;
            });
            chosenYears = [...yearsLoaded, ...chosenYears];
            chosenYears = removeDuplicate(chosenYears);
            setYearsLoaded(chosenYears);
            console.log("Years loaded:");
            console.log(chosenYears);
        }
    }, [sales]);

    return (
        <SalesContext.Provider
            value={{ sales, setSales, yearsLoaded, setYearsLoaded }}
        >
            {children}
        </SalesContext.Provider>
    );
};
